import React from "react";
import './site.scss';

interface SiteProps {
    logoURL: string;
    title: string;
    role: string;
    link: string;
}

const Site: React.FC<SiteProps> = ({ logoURL, title, role, link }) => {
    const accessToken = sessionStorage.getItem('accessToken') || '';

    const htmlEntities = (html: string) =>  {
        return html.replace(/&amp;/gi, '&');
    }

    return (
        <div className="site">
            <div className="site-logo">
                <img src={logoURL} />
            </div>
            <div className="site-details">
                <h4>{htmlEntities(title)}</h4>
                <p>{role}</p>
            </div>
            <div className="site-footer">
                <a href={`${link}?auth=${accessToken}`} target="_blank" rel="nonreferrer">
                    <button type="button">
                        <i className="fa fa-external-link-square-alt"></i> 
                        Visit
                    </button>
                </a>
            </div>
        </div>
    );
}

export default Site;