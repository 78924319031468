import { useQuery } from "@tanstack/react-query";
import React from "react";
import axios from "../../api/axios";
import Loader from "../../components/loader/Loader";
import Site from "../../components/site/Site";
import './sites.scss';

interface SiteData {
    skey: string;
    logo: string;
    site: string;
    role: string;
    link: string;
}

interface SiteResponse {
    result: SiteData[];
}

const Sites: React.FC = () => {
    const accessToken = sessionStorage.getItem('accessToken') || '';

    const { data, isLoading } = useQuery<SiteResponse>({
        queryKey: ['userSiteLists'],
        queryFn: async () => {
            const res = await axios.get<SiteResponse>('getusersitelist', {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data;
        },
        enabled: !!accessToken
    });
    
    const renderSiteList = data?.result
        ?.filter((site: SiteData) => site.skey !== 'pftv9')
        .map((site: SiteData, i: number) => (
            <Site key={i} logoURL={site.logo} title={site.site} role={site.role} link={site.link} />
        )) || [];

    return (
        <div className="sites">
            <div className="sites-header">
                <h2>My Apps:</h2>
            </div>

            <div className="sites-content">
                {isLoading ? (
                    <Loader />
                ) : renderSiteList.length === 0 ? (
                    'No apps found.'
                ) : (
                    renderSiteList
                )}
            </div>
        </div>
    );
};

export default Sites;
