import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import './settings.scss';
import theme1 from '../../assets/theme-1.jpg';
import theme1dark from '../../assets/theme-1-dark.jpg';

interface SecurityQuestionData {
    pword: string;
    sq: string;
    sa: string;
}

interface ThemeData {
    sk: string;
    sv: string;
}

const Settings: React.FC = () => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const accessToken = sessionStorage.getItem('accessToken') || '';
    const theme = sessionStorage.getItem('theme') || '';

    const formSecurityQuestionErrorRef = useRef<HTMLParagraphElement | null>(null);
    const formSecurityQuestionSuccessRef = useRef<HTMLParagraphElement | null>(null);
    const formThemeErrorRef = useRef<HTMLParagraphElement | null>(null);

    const [activeDisplayPanel, setActivePanelDisplay] = useState<string>('security');
    const [showSecurityQuestionForm, setShowSecurityQuestionForm] = useState<boolean>(false);

    const [showSecurityAnswer, setShowSecurityAnswer] = useState<boolean>(false);
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [securityQuestion, setSecurityQuestion] = useState<string>('');
    const [securityAnswer, setSecurityAnswer] = useState<string>('');
    const [formSecurityQuestionErrorMessage, setFormSecurityQuestionErrorMessage] = useState<string>('');
    const [formSecurityQuestionSuccessMessage, setFormSecurityQuestionSuccessMessage] = useState<string>('');
    const [formSecurityQuestionError, setFormSecurityQuestionError] = useState<boolean>(false);
    const [formSecurityQuestionSuccess, setFormSecurityQuestionSuccess] = useState<boolean>(false);
    
    const [selectedTheme, setSelectedTheme] = useState<string>(theme);
    const [formThemeError, setFormThemeError] = useState<boolean>(false);
    const [formThemeErrorMessage, setFormThemeErrorMessage] = useState<string>('');

    const userSecurityQuestionKey = 'userSecurityQuestion';

    const { data: userSecurityQuestion } = useQuery({
        queryKey: [userSecurityQuestionKey],
        queryFn: async () => {
            const res = await axios.post('getusermeta', JSON.stringify({ mk: 'security_question' }), {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data.data;
        },
        enabled: !!accessToken
    });

    useEffect(() => {
        setFormSecurityQuestionError(false);
        setFormSecurityQuestionErrorMessage('');
        setFormSecurityQuestionSuccess(false);
        setFormSecurityQuestionSuccessMessage('');
    }, [currentPassword, securityQuestion, securityAnswer]);

    useEffect(() => {
        setFormThemeError(false);
        setFormThemeErrorMessage('');
    }, [selectedTheme]);

    const handleShowSecurityAnswer = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowSecurityAnswer(prev => !prev);
    }

    const handleShowSecurityQuestionForm = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowSecurityQuestionForm(true);
    }

    const handleHideSecurityQuestionForm = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setCurrentPassword('');
        setSecurityQuestion('');
        setSecurityAnswer('');
        setFormSecurityQuestionSuccess(false);
        setFormSecurityQuestionSuccessMessage('');
        setShowSecurityQuestionForm(false);
    }

    const submitSecurityQuestionMutation = useMutation({
        mutationFn: async (securityQuestionData: SecurityQuestionData) => {
            const res = await axios.post('savesqa', securityQuestionData, {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data;
        },
        onSuccess: (data: any) => {
            setFormSecurityQuestionSuccessMessage(data.msg);
            setFormSecurityQuestionSuccess(true);
            formSecurityQuestionSuccessRef.current?.focus();
            queryClient.invalidateQueries();
            
            setTimeout(() => {
                setCurrentPassword('');
                setSecurityQuestion('');
                setSecurityAnswer('');
                setFormSecurityQuestionSuccess(false);
                setFormSecurityQuestionSuccessMessage('');
                setShowSecurityQuestionForm(false);
            }, 3000);
        },
        onError: (error: any) => {
            setFormSecurityQuestionErrorMessage(error?.response?.data.msg || 'No server response!');
            setFormSecurityQuestionError(true);
            formSecurityQuestionErrorRef.current?.focus();
        }
    });

    const handleSubmitSecurityQuestionDetails = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submitSecurityQuestionMutation.mutate({
            pword: currentPassword,
            sq: securityQuestion,
            sa: securityAnswer
        });
    }

    const handleActivePanelDisplay = (e: React.MouseEvent<HTMLButtonElement>, panel: string) => {
        e.preventDefault();
        setActivePanelDisplay(panel);
        setCurrentPassword('');
        setSecurityQuestion('');
        setSecurityAnswer('');
        setFormSecurityQuestionSuccess(false);
        setFormSecurityQuestionSuccessMessage('');
        setShowSecurityQuestionForm(false);
        setSelectedTheme(theme);
    }

    const submitThemeMutation = useMutation({
        mutationFn: async (themeData: ThemeData) => {
            const res = await axios.post('savesetting', themeData, {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data;
        },
        onSuccess: (data: any) => {
            sessionStorage.setItem('theme', selectedTheme);
            navigate('/cRJxA/settings');
        },
        onError: (error: any) => {
            setFormThemeErrorMessage(error?.response?.data.msg || 'No server response!');
            setFormThemeError(true);
            formThemeErrorRef.current?.focus();
        }
    });

    const handleSubmitThemeDetails = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        submitThemeMutation.mutate({
            sk: 'camp_theme',
            sv: selectedTheme
        });
    }

    return (
        <div className="settings">
            <div className="page-header">
                <h2>Settings</h2>
                <Link to="/cRJxA/dashboard">
                    <button type="button">
                        <i className="fa fa-times"></i>
                    </button>
                </Link>
            </div>

            <div className="page-body">
                <div className="sidebar">
                    <button type="button" className={`${activeDisplayPanel === 'security' && 'active'}`} onClick={(e) => handleActivePanelDisplay(e, 'security')}>
                        SECURITY
                    </button>
                    <button type="button" className={`${activeDisplayPanel === 'appearance' && 'active'}`} onClick={(e) => handleActivePanelDisplay(e, 'appearance')}>
                        APPEARANCE
                    </button>
                </div>

                <div className="page-content">
                    {/* Security Wrapper */}
                    <div className={`security ${activeDisplayPanel === 'security' && 'show'}`}>
                        <div className="panel">
                            <div className="panel-header">
                                <h3>
                                    <i className="fa fa-comment"></i>
                                    <span>Security Question</span>
                                </h3>
                                <button type="button" onClick={handleShowSecurityQuestionForm}>
                                    EDIT
                                </button>
                            </div>
                            <div className="panel-content">
                                <p>Select a security question and input your answer so you can reset your password in case you have trouble signing in to your CAMP account.</p>
                                <div>
                                    <p>Security Question:</p>
                                    <h3>{userSecurityQuestion}</h3>
                                </div>
                                <form className={`form-security-question ${showSecurityQuestionForm && 'show'}`} onSubmit={handleSubmitSecurityQuestionDetails}>
                                    <p ref={formSecurityQuestionErrorRef} className={`form-message error-message ${formSecurityQuestionError && 'show'}`}>
                                        <i className="fa fa-exclamation-triangle"></i>
                                        <span>{formSecurityQuestionErrorMessage}</span>
                                    </p>
                                    <p ref={formSecurityQuestionSuccessRef} className={`form-message success-message ${formSecurityQuestionSuccess && 'show'}`}>
                                        <i className="fa fa-check-circle"></i>
                                        <span>{formSecurityQuestionSuccessMessage}</span>
                                    </p>
                                    <div>
                                        <label>Current Password</label>
                                        <input 
                                            type="password"
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            value={currentPassword}
                                            disabled={submitSecurityQuestionMutation.isPending}
                                            required
                                        />
                                    </div>
                                    <div>
                                        <label>Security Question:</label>
                                        <select 
                                            onChange={(e) => setSecurityQuestion(e.target.value)}
                                            value={securityQuestion} 
                                            disabled={submitSecurityQuestionMutation.isPending} 
                                            required
                                        >
                                            <option value=""></option>
                                            {/* Security Questions Options */}
                                            {["What is the food you least liked as a child?", "What is the name of your first stuffed animal?", "What did you earn your first medal or award for?", "What is your favorite security question?", "What is the toy/stuffed animal you liked the most as a kid?", "What was the first computer game you played?", "What is your favorite movie quote?", "What was the mascot of the first sports team you played on?", "What music album or song did you first purchase?", "What is your favorite piece of art?", "What was your grandmother's favorite dessert?", "What was the first thing you learned to cook?", "What was your dream job as a child?", "Where did you meet your spouse/significant other?", "Where did you go for your favorite vacation?", "Where were you on New Year's Eve in the year 2000?", "Who is your favorite speaker/orator?", "Who is your favorite book/movie character?", "Who is your favorite sports player?"].map(question => (
                                                <option key={question} value={question}>{question}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <label>Answer</label>
                                        <div className="input-with-button">
                                            <input 
                                                type={showSecurityAnswer ? 'text' : 'password'}
                                                onChange={(e) => setSecurityAnswer(e.target.value)}
                                                value={securityAnswer}
                                                disabled={submitSecurityQuestionMutation.isPending}
                                                required
                                            />
                                            <button type="button" className="change-view" onClick={handleShowSecurityAnswer}>
                                                <i className={`fa ${showSecurityAnswer ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="button-group">
                                        <button type="submit" disabled={submitSecurityQuestionMutation.isPending}>
                                            <span>SAVE</span>
                                            {submitSecurityQuestionMutation.isPending && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '5px' }}></i>}
                                        </button>
                                        <button type="button" disabled={submitSecurityQuestionMutation.isPending} onClick={handleHideSecurityQuestionForm}>
                                            <span>CANCEL</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    {/* Appearance Wrapper */}
                    <div className={`appearance ${activeDisplayPanel === 'appearance' && 'show'}`}>
                        <div className="panel">
                            <div className="panel-header">
                                <h3>
                                    <i className="fa fa-lock"></i>
                                    <span>Theme</span>
                                </h3>
                                <button type="button" onClick={handleSubmitThemeDetails} disabled={submitThemeMutation.isPending}>
                                    <span>SAVE</span>
                                    {submitThemeMutation.isPending && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '5px' }}></i>}
                                </button>
                            </div>
                            <div className="panel-content">
                                <p ref={formThemeErrorRef} className={`theme-error ${formThemeError && 'show'}`}>
                                    <i className="fa fa-exclamation-triangle"></i>
                                    <span>{formThemeErrorMessage}</span>
                                </p>
                                <form>
                                    <label>
                                        <img src={theme1} alt="Theme 1" />
                                        <input 
                                            type="radio"
                                            onChange={(e) => setSelectedTheme(e.target.value)}
                                            value="theme-1"
                                            className={`${selectedTheme === 'theme-1' && 'active'}`}
                                            checked={selectedTheme === 'theme-1'}
                                            disabled={submitThemeMutation.isPending}
                                        />
                                    </label>
                                    <label>
                                        <img src={theme1dark} alt="Theme 1 Dark" />
                                        <input 
                                            type="radio"
                                            onChange={(e) => setSelectedTheme(e.target.value)}
                                            value="theme-1-dark"
                                            className={`${selectedTheme === 'theme-1-dark' && 'active'}`}
                                            checked={selectedTheme === 'theme-1-dark'}
                                            disabled={submitThemeMutation.isPending}
                                        />
                                    </label>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;
