import React, { useEffect, useRef, useState } from "react";
import axios from "../../api/axios";
import logo from '../../assets/msw-logo-white.png';
import './forgot-password.scss';

const ForgotPassword: React.FC = () => {
    const errorRef = useRef<HTMLDivElement | null>(null);
    const successRef = useRef<HTMLDivElement | null>(null);

    const [email, setEmail] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');
    const [formSubmit, setFormSubmit] = useState<boolean>(false);

    useEffect(() => {
        setErrorMessage('');
        setSuccessMessage('');
    }, [email]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmit(true);

        try {
            const response = await axios.post('forgotpassword', JSON.stringify({ email }), {
                headers: { 'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN }
            });

            if (response) {
                setSuccessMessage(response.data.msg);
                successRef.current?.focus();
                setFormSubmit(false);
            }
        } catch (error: any) {
            if (!error?.response) {
                setErrorMessage('No server response!');
            } else {
                setErrorMessage(error.response?.data.msg);
            }

            errorRef.current?.focus();
            setFormSubmit(false);
        }
    };

    return (
        <div className="forgot-password">
            <div className="container">
                <form onSubmit={handleSubmit}>
                    <div ref={errorRef} className={`message error ${errorMessage ? 'show' : ''}`} aria-live="assertive">
                        <p>
                            <i className="fa fa-exclamation-triangle"></i> 
                            <span>{errorMessage}</span>
                        </p>
                    </div>

                    <div ref={successRef} className={`message success ${successMessage ? 'show' : ''}`} aria-live="assertive">
                        <p>
                            <i className="fa fa-check-circle"></i> 
                            <span>{successMessage}</span>
                        </p>
                    </div>

                    <div className="header">
                        <div className="logo-container">
                            <div className="logo">
                                <img src={logo} alt="MSW" />
                            </div>
                        </div>

                        {/* waves container */}
                        <div className="waves">
                            <svg className='waves' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='0 24 150 28' preserveAspectRatio='none' shapeRendering='auto'>
                                <defs>
                                    <path id='gentle-wave' d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z' />
                                </defs>
                                <g className='parallax'>
                                    <use xlinkHref='#gentle-wave' x='48' y='0' fill='rgba(255,255,255,0.7)' />
                                    <use xlinkHref='#gentle-wave' x='48' y='3' fill='rgba(255,255,255,0.5)' />
                                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                                </g>
                            </svg>
                        </div>
                    </div>

                    <div className="body">
                        <input 
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            placeholder="Email Address"
                            autoComplete="off"
                            required
                            disabled={formSubmit}
                        />

                        <button type="submit" disabled={formSubmit}>
                            SUBMIT 
                            {formSubmit && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '5px' }}></i>}
                        </button>
                    </div>
                </form>
            </div>  
        </div>
    );
};

export default ForgotPassword;
