import React from "react";
import './table-loader.scss';

const ITPDHelpdeskLoader = () => {
    return (
        <table className="table-loader loading">
            <thead>
                <tr>
                    <th>Ticket No.</th>
                    <th>Subject</th>
                    <th>Incident Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <>{
                    [...Array(10)].map((_, i) => (
                        <tr key={i}>
                            <td><span></span></td>
                            <td><span></span></td>
                            <td><span></span></td>
                            <td><span></span></td>
                        </tr>
                    ))
                }</>
            </tbody>
        </table>
    )
}

export default ITPDHelpdeskLoader;