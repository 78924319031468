import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import './tms.scss';
import Swal from "sweetalert2";
import { browserName, deviceType, getUA, osName, osVersion } from "react-device-detect";
import axioscamp from "../../api/axioscamp";

interface TmsProps {
    format: '12' | '24';
}

interface ButtonStates {
    timein: string;  
    timeout: string; 
    breakin: string; 
    breakout: string;
}

interface BreakButtonStates {
    min15: string;
    min30: string;
    hr1: string;
}

interface TimeInOutDetails {
    target: string;
    uname: string;
    name: string;
    dept: string;
    dept_code: string;
    outlet: string;
    outlet_code: string;
    useragent: string;
    browser: string;
    device: string;
    os: string;
    latitude: string;
    longitude: string;
}

interface BreakTimeDetails {
    target: string;
    break: string;
    uname: string;
    name: string;
    dept: string;
    dept_code: string;
    outlet: string;
    outlet_code: string;
    useragent: string;
    browser: string;
    device: string;
    os: string;
    latitude: string;
    longitude: string;
}

const Tms: React.FC<TmsProps> = ({ format }) => {
    const useragent = getUA;

    const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        timerProgressBar: true,
    });

    const accessToken = sessionStorage.getItem('accessToken') || '';
    const uname = sessionStorage.getItem('uname') || '';
    const name = sessionStorage.getItem('name') || '';
    const department = sessionStorage.getItem('department') || '';
    const department_code = sessionStorage.getItem('department_code') || '';
    const outlet = sessionStorage.getItem('outlet') || '';
    const outlet_code = sessionStorage.getItem('outlet_code') || '';

    const [time, setTime] = useState<Date>(new Date());
    const [showBreakTimeModal, setShowBreakTimeModal] = useState<boolean>(false);
    const [clickTimeButton, setClickTimeButton] = useState<string>('');
    const [breakButtons, setBreakButtons] = useState<BreakButtonStates | null>(null);

    const DEFAULT_LOCATION = {
        latitude: "",
        longitude: "",
    };

    const getLocation = () => {
        return new Promise<{ latitude: string, longitude: string }>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        latitude: position.coords.latitude.toString(),
                        longitude: position.coords.longitude.toString(),
                    });
                },
                () => {
                    resolve(DEFAULT_LOCATION);  // Fallback if geolocation fails or is denied
                }
            );
        });
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);

        // Cleanup the event listener on unmount
        return () => { clearInterval(intervalId); };
    }, []);

    const formattedTime = () => {
        const hours = time.getHours();
        const minutes = time.getMinutes();
        const seconds = time.getSeconds();
        const formattedHours = format === "12" ? (hours % 12 || 12) : hours;
        const ampm = format === "12" ? (hours >= 12 ? 'PM' : 'AM') : "";
        return `${formattedHours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds} ${ampm}`;
    };

    const { data: buttonStates = { timein: "disabled", timeout: "disabled", breakin: "disabled", breakout: "disabled" }, refetch: refetchButtons } = useQuery<ButtonStates>({
        queryKey: ['userButtons', uname],
        queryFn: async () => {
            const res = await axioscamp.post('tms/checkuserbuttons', JSON.stringify({ uname }), {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data.data;
        },
        enabled: !!uname && !!accessToken,
        staleTime: Infinity,
    });

    useEffect(() => {
        // Keydown event listener for logging time
        const keyDownHandler = (event: KeyboardEvent) => {
            if (event.key === 'F1') {
                event.preventDefault();
                if (buttonStates?.timein !== "disabled") handleLogTime('f1'); 
            } else if (event.key === 'F2') {
                event.preventDefault();
                if (buttonStates?.breakin !== "disabled") handleBreakTimeModal('f2'); 
            } else if (event.key === 'F3') {
                event.preventDefault();
                if (buttonStates?.breakout !== "disabled") handleBreakTimeModal('f3'); 
            } else if (event.key === 'F4') {
                event.preventDefault();
                if (buttonStates?.timeout !== "disabled") handleLogTime('f4'); 
            }
        };

        // Attach keydown event listener
        window.addEventListener('keydown', keyDownHandler);

        // Cleanup the event listener on unmount
        return () => {
            window.removeEventListener('keydown', keyDownHandler);
        };
    }, [buttonStates]);

    const logTimeMutation = useMutation({
        mutationFn: async (logDetails: TimeInOutDetails) => {
            const res = await axioscamp.post('tms/logusertime', logDetails, {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data.msg;
        },
        onSuccess: (data) => {
            setClickTimeButton('');
            toast.fire({ icon: 'success', title: data });
            refetchButtons();
        },
        onError: (error: any) => {
            setClickTimeButton('');
            toast.fire({
                icon: 'error',
                title: error.response?.data.msg || 'Something went wrong logging your time!'
            });
            refetchButtons();
        }
    });

    const handleLogTime = async (action: string) => {
        const { latitude, longitude } = await getLocation();
        const label = action === 'f1' ? 'Time In' : 'Time Out';
    
        Swal.fire({
            text: `Are you sure you want to log your ${label}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                setClickTimeButton(action);
                logTimeMutation.mutate({
                    target: action,
                    uname,
                    name,
                    dept: department,
                    dept_code: department_code,
                    outlet,
                    outlet_code,
                    useragent,
                    browser: browserName,
                    device: deviceType,
                    os: `${osName} ${osVersion}`,
                    latitude,
                    longitude
                });
            }
        });
    };

    const breakButtonsMutation = useMutation<BreakButtonStates, Error, string>({
        mutationFn: async (target) => {
            const res = await axioscamp.post('tms/checkuserbreakbuttons', JSON.stringify({ uname, target }), {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data;
        },
        onSuccess: (data: any) => {
            setShowBreakTimeModal(true);
            setBreakButtons(data.data);
        },
        onError: (error: any) => {
            setShowBreakTimeModal(false);
            toast.fire({ icon: 'error', title: error.response?.data.msg || 'Something went wrong while checking break time buttons!' });
        }
    });

    const handleBreakTimeModal = (action: string) => {
        setClickTimeButton(action);
        breakButtonsMutation.mutate(action);
    }

    const breakTimeMutation = useMutation({
        mutationFn: async (breakTimeData: BreakTimeDetails) => {
            const res = await axioscamp.post('tms/loguserbreaktime', breakTimeData, {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data;
        },
        onSuccess: (data) => {
            setClickTimeButton('');
            setShowBreakTimeModal(false);
            toast.fire({ icon: 'success', title: data.msg });
            refetchButtons();
        },
        onError: (error: any) => {
            toast.fire({ icon: 'error', title: error.response?.data.msg || 'Something went wrong while saving your break time!' });
        }
    });

    const handleBreakButtonClick = async (duration: string) => {
        const { latitude, longitude } = await getLocation();
        const label = duration === 'min15' ? '15 minutes' : duration === 'min30' ? '30 minutes' : '1 hour';
        const type = clickTimeButton === 'f2' ? 'start' : 'end';

        Swal.fire({
            text: `Are you sure you want to ${type} your ${label} break?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                breakTimeMutation.mutate({
                    target: duration,
                    break: clickTimeButton,
                    uname,
                    name,
                    dept: department,
                    dept_code: department_code,
                    outlet,
                    outlet_code,
                    useragent,
                    browser: browserName,
                    device: deviceType,
                    os: `${osName} ${osVersion}`,
                    latitude,
                    longitude
                });
            }
        });
    };

    return (
        <div className="tms">
            <div className="clock">{formattedTime()}</div>

            <div className="buttons">
                <div>
                    <button type="button" aria-label="Time In (F1)" onClick={() => handleLogTime('f1')} disabled={buttonStates?.timein === "disabled" || logTimeMutation.isPending}>
                        Time In (F1) 
                        {logTimeMutation.isPending && logTimeMutation.variables?.target === 'f1' && <i className="fa fa-spinner fa-spin"></i>}
                    </button>
                    <button type="button" aria-label="End Break(F3)" onClick={() => handleBreakTimeModal('f3')} disabled={buttonStates?.breakout === "disabled" || logTimeMutation.isPending}>
                        End Break (F3) 
                        {logTimeMutation.isPending && logTimeMutation.variables?.target === 'f3' && <i className="fa fa-spinner fa-spin"></i>}
                    </button>
                </div>
                <div>
                    <button type="button" aria-label="Start Break (F2)" onClick={() => handleBreakTimeModal('f2')} disabled={buttonStates?.breakin === "disabled" || logTimeMutation.isPending}>
                        Start Break (F2) 
                        {logTimeMutation.isPending && logTimeMutation.variables?.target === 'f2' && <i className="fa fa-spinner fa-spin"></i>}
                    </button>
                    <button type="button" aria-label="Time Out (F4)" onClick={() => handleLogTime('f4')} disabled={buttonStates?.timeout === "disabled" || logTimeMutation.isPending}>
                        Time Out (F4) 
                        {logTimeMutation.isPending && logTimeMutation.variables?.target === 'f4' && <i className="fa fa-spinner fa-spin"></i>}
                    </button>
                </div>
            </div>

            {showBreakTimeModal && (
                <div className="modal tms-break-time">
                    <div className="wrapper">
                        <button type="button" className="close-modal" onClick={() => { setShowBreakTimeModal(false) }}>
                            <i className="fa fa-times"></i>
                        </button>
                        <button type="button" onClick={() => handleBreakButtonClick('min15')} disabled={breakTimeMutation.isPending || breakButtons?.min15 === 'disabled'}>
                            15<br />minutes
                            {breakTimeMutation.isPending && breakTimeMutation.variables?.target === 'min15' && <i className="fa fa-spinner fa-spin" style={{ display: 'block' }}></i>}
                        </button>
                        <button type="button" onClick={() => handleBreakButtonClick('min30')} disabled={breakTimeMutation.isPending || breakButtons?.min30 === 'disabled'}>
                            30<br />minutes
                            {breakTimeMutation.isPending && breakTimeMutation.variables?.target === 'min30' && <i className="fa fa-spinner fa-spin" style={{ display: 'block' }}></i>}
                        </button>
                        <button type="button" onClick={() => handleBreakButtonClick('hr1')} disabled={breakTimeMutation.isPending || breakButtons?.hr1 === 'disabled'}>
                             1<br />hour
                             {breakTimeMutation.isPending && breakTimeMutation.variables?.target === 'hr1' && <i className="fa fa-spinner fa-spin" style={{ display: 'block' }}></i>}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Tms;
