import React, { FormEvent, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import './verify-account.scss';
import logo from '../../assets/msw-logo.png';
import axios from "../../api/axios";
import Error from "../../components/error/Error";
import Success from "../../components/success/Success";

interface UserDetails {
    delegation: string;
    userID: string;
    uname: string;
    name: string;
    email: string;
    employeeID: string;
    gelID: string;
    department: string;
    position: string;
    operator?: string;
    outlet?: string;
    contactNumber: string;
    civilStatus: string;
    gender: string;
    nationality: string;
    address: string;
}

const VerifyAccount: React.FC = () => {
    const { token } = useParams<{ token: string }>();

    const [pageError, setPageError] = useState<boolean>(false);
    const [pageErrorMessage, setPageErrorMessage] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showSecurityAnswer, setShowSecurityAnswer] = useState<boolean>(false);
    
    const formTemporaryPasswordErrorRef = useRef<HTMLParagraphElement | null>(null);
    const [temporaryPasswordErrorMessage, setTemporaryPasswordErrorMessage] = useState<string>('');
    const [formTemporaryPasswordError, setFormTemporaryPasswordError] = useState<boolean>(false);
    const [formTemporaryPasswordSuccess, setFormTemporaryPasswordSuccess] = useState<boolean>(false);
    const [formSubmitTemporaryPassword, setFormSubmitTemporaryPassword] = useState<boolean>(false);
    const [temporaryPassword, setTemporaryPassword] = useState<string>('');

    const passwordErrorRef = useRef<HTMLParagraphElement | null>(null);
    const formAccountUpdateErrorRef = useRef<HTMLParagraphElement | null>(null);
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [accountUpdateErrorMessage, setAccountUpdateErrorMessage] = useState<string>('');
    const [formAccountUpdateError, setFormAccountUpdateError] = useState<boolean>(false);
    const [formAccountUpdateSuccess, setFormAccountUpdateSuccess] = useState<boolean>(false);
    const [formSubmitAccountUpdate, setFormSubmitAccountUpdate] = useState<boolean>(false);

    const [image, setImage] = useState<string>('');
    const [avatar, setAvatar] = useState<string>(logo);
    const [selectedAvatar, setSelectedAvatar] = useState<File | null>(null);

    const [userDetails, setUserDetails] = useState<UserDetails>({
        delegation: '',
        userID: '',
        uname: '',
        name: '',
        email: '',
        employeeID: '',
        gelID: '',
        department: '',
        position: '',
        operator: undefined,
        outlet: undefined,
        contactNumber: '',
        civilStatus: '',
        gender: '',
        nationality: '',
        address: ''
    });

    const [password, setPassword] = useState<string>('');
    const [birthdate, setBirthdate] = useState<string>('');
    const [securityQuestion, setSecurityQuestion] = useState<string>('');
    const [securityAnswer, setSecurityAnswer] = useState<string>('');

    const handleNullData = (value: any): string => value === null ? '-' : value;

    useEffect(() => {
        setPageError(false);
        setPageErrorMessage('');
        setFormSubmitTemporaryPassword(false);
        setFormSubmitAccountUpdate(false);
    }, []);

    useEffect(() => {
        setTemporaryPasswordErrorMessage('');
        setFormTemporaryPasswordError(false);
        setFormTemporaryPasswordSuccess(false);
    }, [temporaryPassword]);

    useEffect(() => {
        setAccountUpdateErrorMessage('');
        setFormAccountUpdateError(false);
        setFormAccountUpdateSuccess(false);
    }, [userDetails.contactNumber, birthdate, userDetails.civilStatus, userDetails.gender, userDetails.nationality, securityQuestion, securityAnswer, userDetails.address]);

    useEffect(() => {
        if (selectedAvatar) {
            setAvatar(URL.createObjectURL(selectedAvatar));
        } else {
            setAvatar(image !== '' ? image : logo);
        }
    }, [selectedAvatar, image]);

    const handleShowPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowPassword(prev => !prev);
    }

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length < 6 || value.length > 10 || !/[A-Z]/.test(value) || !/[a-z]/.test(value) || !/[0-9]/.test(value)) {
            setPasswordError(true);
            passwordErrorRef.current?.focus();
        } else {
            setPasswordError(false);
        }
        setPassword(value);
    }

    const handleContactNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length <= 11) {
            setUserDetails(prev => ({ ...prev, contactNumber: value }));
        }
    }

    const handleShowSecurityAnswer = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowSecurityAnswer(prev => !prev);
    }

    const handleTemporaryPasswordVerification = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitTemporaryPassword(true);

        try {
            const response = await axios.post('verifytemppwd', JSON.stringify({ token, tpwd: temporaryPassword }), {
                headers: { 'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN }
            });

            if (response.data.status === 'success') {
                setFormTemporaryPasswordSuccess(true);
                const details = response.data.details;

                setUserDetails(prev => ({
                    ...prev,
                    delegation: handleNullData(details.delegation),
                    userID: handleNullData(details.uid),
                    uname: handleNullData(details.username),
                    name: `${handleNullData(details.firstname)} ${handleNullData(details.lastname)}`,
                    email: handleNullData(details.emailaddress),
                    employeeID: handleNullData(details.employeeid),
                    gelID: handleNullData(details.gelid),
                    department: handleNullData(details.department),
                    position: handleNullData(details.position),
                    operator: handleNullData(details.operator),
                    outlet: handleNullData(details.outlet),
                    contactNumber: handleNullData(details.contactnumber),
                    civilStatus: handleNullData(details.civilstatus),
                    gender: handleNullData(details.gender),
                    nationality: handleNullData(details.nationality),
                    address: handleNullData(details.address),
                }));

                setFormAccountUpdateSuccess(details.verified);
            }
            setFormSubmitTemporaryPassword(false);
        } catch (error: any) {
            if (!error?.response) {
                setTemporaryPasswordErrorMessage('No server response!');
            } else {
                setTemporaryPasswordErrorMessage(error.response?.data.msg);
            }
            formTemporaryPasswordErrorRef.current?.focus();
            setFormTemporaryPasswordError(true);
            setFormSubmitTemporaryPassword(false);
        }  
    }

    const handleAccountUpdateSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        if (selectedAvatar) {
            formData.append('avatar', selectedAvatar);
        }
        formData.append('uname', userDetails.uname);
        formData.append('uid', userDetails.userID);
        setFormSubmitAccountUpdate(true)

        try {
            const response = await axios.post('verifyaccount', formData, {
                headers: { 'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN }
            });

            if (response.data.status === 'success') {
                setFormAccountUpdateSuccess(true);
            }
            setFormSubmitAccountUpdate(false);
        } catch (error: any) {
            if (!error?.response) {
                setAccountUpdateErrorMessage('No server response!');
            } else {
                setAccountUpdateErrorMessage(error.response?.data.msg);
            }
            formAccountUpdateErrorRef.current?.focus();
            setFormAccountUpdateError(true);
            setFormSubmitAccountUpdate(false);
        }
    }
    
    return (
        <>
            { pageError
                ? <Error message={pageErrorMessage} />
                : formAccountUpdateSuccess
                    ? <Success message="Account successfully verified." />
                    : (
                        <div className="verify-account">
                            <div className="logo">
                                <img src={logo} alt='logo' />
                                <div className='title'>
                                    <p>Centralized <span>Access</span></p>
                                    <p>Management <span>Program</span></p>
                                </div>
                            </div>

                            <div className="container">
                                {/* Temporary Password Verification Form */}
                                {!formTemporaryPasswordSuccess 
                                    ? (
                                        <div className="verify-temporary-password">
                                            <form onSubmit={handleTemporaryPasswordVerification}>
                                                <input 
                                                    type="password"
                                                    placeholder="Temporary Password"
                                                    onChange={(e) => setTemporaryPassword(e.target.value)}
                                                    value={temporaryPassword}
                                                    disabled={formSubmitTemporaryPassword}
                                                    required
                                                />
                                                <p ref={formTemporaryPasswordErrorRef} className={`error-message ${formTemporaryPasswordError ? 'show' : ''}`}>
                                                    {temporaryPasswordErrorMessage}
                                                </p>
                                                <button disabled={formSubmitTemporaryPassword}>
                                                    SUBMIT
                                                    { formSubmitTemporaryPassword && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '5px' }}></i> }
                                                </button>
                                            </form>
                                        </div>
                                    ) : (
                                        <div className="account-update">
                                            <p ref={formAccountUpdateErrorRef} className={`error-message ${formAccountUpdateError ? 'show' : ''}`}>
                                                <i className="fa fa-exclamation-triangle"></i>
                                                <span>{accountUpdateErrorMessage}</span>
                                            </p>

                                            <form onSubmit={handleAccountUpdateSubmit} encType="multipart/form-data">
                                                <div className="details">
                                                    <div className="container">
                                                        <div className="avatar">
                                                            <img src={avatar} alt="avatar" />
                                                            <label>
                                                                <input
                                                                    type="file"
                                                                    accept=".jpg, .jpeg, .png"
                                                                    onChange={(e) => {
                                                                        if (e.target.files) {
                                                                            setSelectedAvatar(e.target.files[0]);
                                                                        }
                                                                    }}
                                                                    disabled={formSubmitAccountUpdate}
                                                                />
                                                                <i className="fa fa-upload"></i>
                                                            </label>
                                                        </div>

                                                        <div className="content">
                                                            <div>
                                                                <label>Username:</label>
                                                                <h4>{userDetails.uname}</h4>
                                                            </div>
                                                            <div>
                                                                <label>Name:</label>
                                                                <h4>{userDetails.name}</h4>
                                                            </div>
                                                            <div>
                                                                <label>Email:</label>
                                                                <h4>{userDetails.email}</h4>
                                                            </div>
                                                            <div>
                                                                <label>Employee ID:</label>
                                                                <h4>{userDetails.employeeID}</h4>
                                                            </div>
                                                            <div>
                                                                <label>Gel ID:</label>
                                                                <h4>{userDetails.gelID}</h4>
                                                            </div>
                                                            <div>
                                                                <label>Department:</label>
                                                                <h4>{userDetails.department}</h4>
                                                            </div>
                                                            <div>
                                                                <label>Position:</label>
                                                                <h4>{userDetails.position}</h4>
                                                            </div>
                                                            {userDetails.delegation === 'Outlet' || userDetails.delegation === 'Operator' ? (
                                                                <div>
                                                                    <label>Operator:</label>
                                                                    <h4>{userDetails.operator}</h4>
                                                                </div>
                                                            ) : null}

                                                            {userDetails.delegation === 'Outlet' ? (
                                                                <div>
                                                                    <label>Outlet:</label>
                                                                    <h4>{userDetails.outlet}</h4>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="input-wrapper">
                                                        <div>
                                                            <label>Password:</label>
                                                            <div className="input-with-button">
                                                                <input
                                                                    type={showPassword ? 'text' : 'password'}
                                                                    autoComplete="off"
                                                                    onChange={handlePassword}
                                                                    value={password}
                                                                    disabled={formSubmitAccountUpdate}
                                                                    required
                                                                />
                                                                <button type="button" className="change-view" onClick={handleShowPassword}>
                                                                    <i className={`fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                                </button>
                                                            </div>
                                                            <p ref={passwordErrorRef} className={`error-message ${passwordError ? 'show' : ''}`}>
                                                                Password should be between 6 to 10 characters and a combination of an uppercase, lowercase, and a number.
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <label>Contact Number:</label>
                                                            <input 
                                                                type="number"
                                                                autoComplete="off"
                                                                onChange={handleContactNumber}
                                                                value={userDetails.contactNumber}
                                                                maxLength={11}
                                                                minLength={11}
                                                                disabled={formSubmitAccountUpdate}
                                                                required
                                                            />
                                                        </div>

                                                        <div>
                                                            <label>Birthdate:</label>
                                                            <input 
                                                                type="date"
                                                                autoComplete="off"
                                                                onChange={(e) => { setBirthdate(e.target.value) }}
                                                                value={birthdate}
                                                                disabled={formSubmitAccountUpdate}
                                                                required
                                                            />
                                                        </div>

                                                        <div>
                                                            <label>Civil Status:</label>
                                                            <select
                                                                onChange={(e) => { setUserDetails(prev => ({ ...prev, civilStatus: e.target.value })) }}
                                                                value={userDetails.civilStatus}
                                                                disabled={formSubmitAccountUpdate}
                                                                required
                                                            >
                                                                <option value=''></option>
                                                                <option value='Single'>Single</option>
                                                                <option value='Married'>Married</option>
                                                                <option value='Widowed'>Widowed</option>
                                                                <option value='Separated'>Separated</option>
                                                                <option value='Divorced'>Divorced</option>
                                                            </select>
                                                        </div>

                                                        <div>
                                                            <label>Gender:</label>
                                                            <select 
                                                                onChange={(e) => { setUserDetails(prev => ({ ...prev, gender: e.target.value })) }}
                                                                value={userDetails.gender}
                                                                disabled={formSubmitAccountUpdate}
                                                                required 
                                                            >
                                                                <option value=""></option>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                            </select>
                                                        </div>

                                                        <div>
                                                            <label>Nationality:</label>
                                                            <input 
                                                                type="text"
                                                                autoComplete="off"
                                                                onChange={(e) => { setUserDetails(prev => ({ ...prev, nationality: e.target.value })) }}
                                                                value={userDetails.nationality}
                                                                disabled={formSubmitAccountUpdate}
                                                                required
                                                            />
                                                        </div>

                                                        <div>
                                                            <label>Security Question:</label>
                                                            <select 
                                                                onChange={(e) => { setSecurityQuestion(e.target.value) }}
                                                                value={securityQuestion} 
                                                                disabled={formSubmitAccountUpdate} 
                                                                required
                                                            >
                                                                <option value=""></option>
                                                                <option value="What is the food you least liked as a child?">What is the food you least liked as a child?</option>
                                                                <option value="What is the name of your first stuffed animal?">What is the name of your first stuffed animal?</option>
                                                                <option value="What did you earn your first medal or award for?">What did you earn your first medal or award for?</option>
                                                                <option value="What is your favorite security question?">What is your favorite security question?</option>
                                                                <option value="What is the toy/stuffed animal you liked the most as a kid?">What is the toy/stuffed animal you liked the most as a kid?</option>
                                                                <option value="What was the first computer game you played?">What was the first computer game you played?</option>
                                                                <option value="What is your favorite movie quote?">What is your favorite movie quote?</option>
                                                                <option value="What was the mascot of the first sports team you played on?">What was the mascot of the first sports team you played on?</option>
                                                                <option value="What music album or song did you first purchase?">What music album or song did you first purchase?</option>
                                                                <option value="What is your favorite piece of art?">What is your favorite piece of art?</option>
                                                                <option value="What was your grandmother's favorite dessert?">What was your grandmother's favorite dessert?</option>
                                                                <option value="What was the first thing you learned to cook?">What was the first thing you learned to cook?</option>
                                                                <option value="What was your dream job as a child?">What was your dream job as a child?</option>
                                                                <option value="Where did you meet your spouse/significant other?">Where did you meet your spouse/significant other?</option>
                                                                <option value="Where did you go for your favorite vacation?">Where did you go for your favorite vacation?</option>
                                                                <option value="Where were you on New Year's Eve in the year 2000?">Where were you on New Year's Eve in the year 2000?</option>
                                                                <option value="Who is your favorite speaker/orator?">Who is your favorite speaker/orator?</option>
                                                                <option value="Who is your favorite book/movie character?">Who is your favorite book/movie character?</option>
                                                                <option value="Who is your favorite sports player?">Who is your favorite sports player?</option>
                                                            </select>
                                                        </div>

                                                        <div>
                                                            <label>Security Answer:</label>
                                                            <div className="input-with-button">
                                                                <input 
                                                                    type={showSecurityAnswer ? 'text' : 'password'}
                                                                    autoComplete="off"
                                                                    onChange={(e) => { setSecurityAnswer(e.target.value) }}
                                                                    value={securityAnswer}
                                                                    disabled={formSubmitAccountUpdate}
                                                                    required
                                                                />
                                                                <button type="button" className="change-view" onClick={handleShowSecurityAnswer}>
                                                                    <i className={`fa ${showSecurityAnswer ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <label>Address:</label>
                                                            <textarea 
                                                                rows={3}
                                                                cols={5}
                                                                onChange={(e) => { setUserDetails(prev => ({ ...prev, address: e.target.value })) }}
                                                                value={userDetails.address}
                                                                disabled={formSubmitAccountUpdate}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="button-wrapper">
                                                    <button disabled={formSubmitAccountUpdate}>
                                                        SUBMIT
                                                        {formSubmitAccountUpdate && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '5px' }}></i>}
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    )
                                }
                            </div>

                            <div className='footer'>
                                <p>&copy; Megasportsworld 2020.</p>
                            </div>
                        </div>
                    )
                }
        </>
    );
}

export default VerifyAccount;
