import { useMutation, useQuery } from "@tanstack/react-query";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import axios from "../../api/axios";
import axioscamp from "../../api/axioscamp";
import './itpd-helpdesk.scss';
import ITPDHelpdeskLoader from "../../components/loader/ITPDHelpdeskLoader";

interface SiteAccessResponse {
    status: string;
}

interface TableColumns {
    ticket: string;
    subject: string;
    incidentdate: string;
    status: string;
}

interface FormDetails {
    priority: string;
    application: string;
    incidentDate: string; 
    description: string;
    expectedBehavior: string;
    stepsToReplicate: string;
    attachments: File[]; // Changed to an array for multiple files
    name: string;
    uname: string;
    email: string;
}

const ITPDHelpdesk: React.FC = () => {
    const accessToken = sessionStorage.getItem('accessToken') || '';
    const uname = sessionStorage.getItem('uname') || '';
    const name = sessionStorage.getItem('name') || '';
    const email = sessionStorage.getItem('email') || '';

    const [priorityDisplay, setPriorityDisplay] = useState<string>('');
    const [formError, setFormError] = useState<boolean>(false);
    const [formErrorMessage, setFormErrorMessage] = useState<string>('');
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [formSuccessMessage, setFormSuccessMessage] = useState<string>('');

    const [formData, setFormData] = useState<FormDetails>({
        priority: '',
        application: '',
        incidentDate: '',
        description: '',
        expectedBehavior: '',
        stepsToReplicate: '',
        attachments: [], 
        name,
        uname,
        email
    });

    const formErrorRef = useRef<HTMLParagraphElement | null>(null);
    const formSuccessRef = useRef<HTMLParagraphElement | null>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(10);

    const priorityNotes: { [key: string]: string } = {
        P1: "BLOCKER / CRITICAL ERRORS. Application is unavailable which causes stoppage in business operations.",
        P2: "MEDIUM ERRORS. Application is unavailable but business operations continue.",
        P3: "MINOR ISSUES. Issues that are not critical while the application is still available."
    };

    useEffect(() => {
        setFormError(false);
        setFormErrorMessage('');
        setFormSuccess(false);
        setFormSuccessMessage('');
    }, [formData]);

    const tableColumns = [
        { name: 'Ticket No.', selector: (row: TableColumns) => row.ticket },
        { name: 'Subject', selector: (row: TableColumns) => row.subject },
        { name: 'Incident Date', selector: (row: TableColumns) => row.incidentdate },
        { name: 'Status', selector: (row: TableColumns) => row.status }
    ];

    const { data: itpdHelpdeskAccess } = useQuery<SiteAccessResponse>({
        queryKey: ['itpdHelpdeskAccess'],
        queryFn: async () => {
            const res = await axios.post<SiteAccessResponse>('checksiteaccess', JSON.stringify({ sitekey: process.env.REACT_APP_ITPD_HELPDESK_TOKEN }), {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data;
        },
        enabled: !!accessToken
    });

    const { data: sites, isLoading: isLoadingSites } = useQuery({
        queryKey: ['userSiteLists'],
        queryFn: async () => {
            const res = await axios.get('getusersitelist', {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data.result;
        },
        enabled: !!accessToken
    });

    const { data: tickets, isLoading: isLoadingTickets } = useQuery({
        queryKey: ['userTickets', uname],
        queryFn: async () => {
            const res = await axioscamp.post('helpdesk/getissues', JSON.stringify({ uname, page: currentPage, rows_per_page: rowPerPage }), {
                headers: { 'X-Aim-Token': accessToken }
            });
            const issues = res.data.data.issues?.map((item: any) => ({
                ticket: item.ticket,
                subject: item.subject,
                incidentdate: item.incidentdate,
                status: item.status === "1" ? <span className="badge badge-secondary">Waiting for Support</span> : 
                       item.status === "2" ? <span className="badge badge-info">Customer Replied</span> : 
                       item.status === "3" ? <span className='badge badge-primary'>In Progress</span> : 
                       item.status === "4" ? <span className='badge badge-info'>On hold</span> : 
                       item.status === "5" ? <span className='badge badge-danger'>Canceled</span> : 
                       item.status === "6" ? <span className='badge badge-success'>Resolved</span> : 
                       item.status === "7" ? <span className='badge badge-warning'>Merged</span> : 
                       item.status === "8" ? <span className='badge badge-primary'>For Verification</span> : 
                       <span className="badge badge-secondary">Pending</span>
            }));
            return { issues, totalRows: res.data.data.totalRows };
        },
        enabled: !!uname && !!accessToken
    });

    const handlePriorityDescriptionDisplay = (val: string) => {
        setPriorityDisplay(val);
    }

    const itpdHelpdeskMutation = useMutation({
        mutationFn: async (ticketDetails: FormDetails) => {
            const formDataToSend = new FormData();
            Object.entries(ticketDetails).forEach(([key, value]) => {
                if (key === 'attachments' && Array.isArray(value)) {
                    value.forEach((file) => formDataToSend.append('attachments[]', file)); 
                } else {
                    formDataToSend.append(key, value);
                }
            });

            const res = await axioscamp.post('helpdesk/sendissue', formDataToSend, {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data;
        },
        onSuccess: (data: any) => {
            setFormSuccessMessage(data.msg);
            setFormSuccess(true);
            formSuccessRef.current?.focus();

            setTimeout(() => {
                setFormData({ 
                    priority: '', 
                    application: '', 
                    incidentDate: '', 
                    description: '', 
                    expectedBehavior: '', 
                    stepsToReplicate: '',
                    attachments: [],
                    name,
                    uname,
                    email
                });
                setFormSuccessMessage('');
                setFormSuccess(false);
                // queryClient.invalidateQueries(['userTickets', uname]);
            }, 2500);
        },
        onError: (error: any) => {
            setFormErrorMessage(!error?.response ? 'No server response' : error.response?.data.msg);
            setFormError(true);
            formErrorRef.current?.focus();

            setTimeout(() => {
                setFormError(false);
                setFormErrorMessage('');
            }, 2500);
        }
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            // Convert the FileList to an array
            const filesArray = Array.from(e.target.files);
            setFormData(prev => ({
                ...prev,
                attachments: filesArray // Update the formData with the new attachments array
            }));
        }
    };
    

    const handleSubmitITPDHelpdesk = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const ticketDetails: FormDetails = {
            ...formData,
            name,
            uname,
            email
        };

        Swal.fire({
            text: `Are you sure you want to send this issue?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                itpdHelpdeskMutation.mutate(ticketDetails);
            }
        });
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    }

    const handleRowsPerPageChange = (newPerPage: number) => {
        setRowPerPage(newPerPage);
        setCurrentPage(1);
    };

    return (
        <>
            {itpdHelpdeskAccess ? (
                <div className="itpd-helpdesk">
                    <div className="page-header">
                        <h2>ITPD Helpdesk</h2>
                        <Link to="/cRJxA/dashboard">
                            <button type="button">
                                <i className="fa fa-times"></i>
                            </button>
                        </Link>
                    </div>

                    <div className="page-body">
                        <div className="table-holder">
                            <DataTable 
                                columns={tableColumns} 
                                data={tickets?.issues || []} 
                                progressPending={isLoadingTickets} 
                                progressComponent={<ITPDHelpdeskLoader />} 
                                pagination
                                paginationServer
                                paginationTotalRows={tickets?.totalRows || 0}
                                onChangeRowsPerPage={handleRowsPerPageChange}
                                onChangePage={handlePageChange}
                                dense
                                highlightOnHover
                                responsive
                            />
                        </div>

                        <form encType="multipart/form-data" onSubmit={handleSubmitITPDHelpdesk}>
                            <p ref={formErrorRef} className={`form-message error-message ${formError ? 'show' : ''}`}>
                                <i className="fa fa-exclamation-triangle"></i>
                                <span>{formErrorMessage}</span>
                            </p>
                            <p ref={formSuccessRef} className={`form-message success-message ${formSuccess ? 'show' : ''}`}>
                                <i className="fa fa-check-circle"></i>
                                <span>{formSuccessMessage}</span>
                            </p>
                            <div>
                                <label>Priority</label>
                                <select
                                    name="priority"
                                    onChange={(e) => {
                                        handleInputChange(e);
                                        handlePriorityDescriptionDisplay(e.target.value);
                                    }}
                                    value={formData.priority}
                                    disabled={itpdHelpdeskMutation.isPending}
                                    required
                                >
                                    <option value=''></option>
                                    <option value='P1'>P1</option>
                                    <option value='P2'>P2</option>
                                    <option value='P3'>P3</option>
                                </select>
                                {priorityDisplay && (
                                    <p className="note">
                                        <i className="fa fa-exclamation-triangle"></i>
                                        <span>{priorityNotes[priorityDisplay]}</span>
                                    </p>
                                )}
                            </div>
                            <div>
                                <label>Application</label>
                                <select
                                    name="application"
                                    onChange={handleInputChange}
                                    value={formData.application}
                                    disabled={itpdHelpdeskMutation.isPending}
                                    required
                                >
                                    <option value=''></option>
                                    {isLoadingSites ? (
                                        <option>Loading sites...</option>
                                    ) : Array.isArray(sites) && sites.length > 0 ? (
                                        sites.map((item: any, index: number) => (
                                            item.skey !== 'pftv9' && <option key={index} value={item.site}>{item.site}</option>
                                        ))
                                    ) : (
                                        <option>No sites available</option>
                                    )}
                                    <option value='Access Identity Management'>Access Identity Management</option>
                                    <option value='Live Odds'>Live Odds</option>
                                    <option value='Prematch Odds'>Prematch Odds</option>
                                </select>
                            </div>
                            <div>
                                <label>Incident Date</label>
                                <input 
                                    type="datetime-local"
                                    name="incidentDate"
                                    onChange={handleInputChange}
                                    value={formData.incidentDate}
                                    disabled={itpdHelpdeskMutation.isPending}
                                    required
                                />
                            </div>
                            <div>
                                <label>Issue Description</label>
                                <textarea 
                                    rows={3}
                                    name="description"
                                    onChange={handleInputChange}
                                    value={formData.description}
                                    disabled={itpdHelpdeskMutation.isPending}
                                    required                                    
                                />
                            </div>
                            <div>
                                <label>Expected Behavior</label>
                                <textarea 
                                    rows={3}
                                    name="expectedBehavior"
                                    onChange={handleInputChange}
                                    value={formData.expectedBehavior}
                                    disabled={itpdHelpdeskMutation.isPending}
                                    required
                                />
                            </div>
                            <div>
                                <label>Steps to Replicate</label>
                                <textarea 
                                    rows={3}
                                    name="stepsToReplicate"
                                    onChange={handleInputChange}
                                    value={formData.stepsToReplicate}
                                    disabled={itpdHelpdeskMutation.isPending}
                                    required
                                />
                            </div>
                            <div>
                                <label>Attachment</label>
                                <input 
                                    type="file"
                                    name="attachments"
                                    accept=".jpg, .jpeg, .png"
                                    onChange={handleFileChange}
                                    disabled={itpdHelpdeskMutation.isPending}
                                    multiple // Allow multiple file selection
                                    required
                                />
                            </div>
                            <div className="button-holder">
                                <button disabled={itpdHelpdeskMutation.isPending}>
                                    <span>SUBMIT</span> 
                                    { itpdHelpdeskMutation.isPending && <i className="fa fa-spinner fa-spin" style={{ marginLeft: '5px' }}></i> }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            ) : (
                <div className="page-error">
                    <p>
                        <i className="fa fa-exclamation-triangle"></i>
                        <span>You are not allowed to access this page.</span>
                    </p>
                </div>
            )}
        </>
    );
}

export default ITPDHelpdesk;
