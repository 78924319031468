import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// define the props type for ErrorBoundary
interface ErrorBoundaryProps {
    children: React.ReactNode; // define children as prop
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    state = { hasError: false };

    static getDerivedStateFromError(error: Error) {
        // update state to indicate error has occured
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        // log error to the console 
        console.error("Error caught in ErrorBoundary: ", error, errorInfo);
    }

    render(): React.ReactNode {
        // if there's an error, do not render anything
        if (this.state.hasError) {
            return null;
        }

        return this.props.children; // render children if no error
    }
}

const queryClient = new QueryClient({});

const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <Router>
                    <Routes>
                        <Route path='/*' element={<App />}></Route>
                    </Routes>
                </Router>
            </ErrorBoundary>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
