import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import axios from "../../api/axios";
import logo from '../../assets/msw-logo.png';
import helpdeskLogo from '../../assets/it-helpdesk.gif';
import './dashboard.scss';
import Tms from "../tms/Tms";

interface SessionResponse {
    status: string;
}

interface SiteAccessResponse {
    status: string;
}

const Dashboard: React.FC = () => {
    const navigate = useNavigate();

    const name = sessionStorage.getItem('name') || ('');
    const image = sessionStorage.getItem('img') || logo;
    const email = sessionStorage.getItem('email') || ('');
    const employeeID = sessionStorage.getItem('emp_id') || ('');
    const currentTheme = sessionStorage.getItem('theme');
    const theme = currentTheme === 'theme-1-dark' ? 'dark' : ('');
    const accessToken = sessionStorage.getItem('accessToken') || ('');

    const { data: session } = useQuery<SessionResponse>({
        queryKey: ['campSession'],
        queryFn: async () => {
            const res = await axios.post<SessionResponse>('checkcampsession', [], {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data;
        },
        enabled: !!accessToken
    });

    const { data: tokenValidity } = useQuery<string>({
        queryKey: ['tokenValidity'],
        queryFn: async () => {
            const res = await axios.get<string>('checkaccesstoken', {
                headers: { 'X-Aim-Token': accessToken }
            });
            return res.data;
        },
        enabled: !!accessToken
    });

    const destroyCurrentSession = async () => {
        await axios.post('destroycampsession', [], {
            headers: { 'X-Aim-Token': accessToken }
        });
        sessionStorage.clear();
        navigate('/');
    };

    useEffect(() => {
        if (
            (session?.status !== 'active' && session?.status !== undefined) ||
            (tokenValidity !== 'active' && tokenValidity !== undefined)
        ) {
            destroyCurrentSession();
        }
    }, [session, tokenValidity, destroyCurrentSession]);

    const { data: itpdHelpdeskAccess } = useQuery<SiteAccessResponse>({
        queryKey: ['itpdHelpdeskAccess'],
        queryFn: async () => {
            const res = await axios.post<SiteAccessResponse>(
                'checksiteaccess', 
                JSON.stringify({ sitekey: process.env.REACT_APP_ITPD_HELPDESK_TOKEN }), 
                {
                    headers: { 'X-Aim-Token': accessToken }
                }
            );
            return res.data;
        },
        enabled: !!accessToken
    });

    const { data: tmsAccess } = useQuery<SiteAccessResponse>({
        queryKey: ['tmsAccess'],
        queryFn: async () => {
            const res = await axios.post<SiteAccessResponse>(
                'checksiteaccess', 
                JSON.stringify({ sitekey: process.env.REACT_APP_TMS_TOKEN }), 
                {
                    headers: { 'X-Aim-Token': accessToken }
                }
            );
            return res.data;
        },
        enabled: !!accessToken
    });

    return (
        <div className={`dashboard ${theme}`}>
            <div className="content">
                <div className="topbar">
                    <div className="logo">
                        <img src={logo} alt='logo' />
                        <div className='title'>
                            <p>Centralized <span>Access</span></p>
                            <p>Management <span>Program</span></p>
                        </div>
                    </div>
                    <div className="details">
                        <p className="name">{name}</p>
                        <div className="avatar" style={{ backgroundImage: `url(${image})` }} />
                        <div className="divider" />
                        <Link to='/cRJxA/settings'>
                            <button type="button" title="Settings">
                                <i className="fa fa-cog"></i>
                            </button>
                        </Link>
                        <button type="button" title="Logout" onClick={destroyCurrentSession}>
                            <i className="fa fa-power-off"></i>
                        </button>
                    </div>
                </div>

                <div className="body">
                    <div className="sidebar-left">
                        <div className="details">
                            <p>
                                <i className="fa fa-envelope"></i> 
                                {email}
                            </p>
                            <p>
                                <i className="fa fa-id-card"></i> 
                                {employeeID}
                            </p>
                        </div>
                    </div>

                    <div className="container">
                        <Outlet />
                    </div>

                    {(itpdHelpdeskAccess?.status === 'success' || tmsAccess?.status === 'success') && (
                        <div className="sidebar-right">
                            {itpdHelpdeskAccess?.status === 'success' && (
                                <div className="helpdesk">
                                    <img src={helpdeskLogo} alt="Helpdesk" />
                                    <Link to="/cRJxA/itpd-helpdesk">
                                        <button type="button">ITPD Helpdesk</button>
                                    </Link>
                                </div>
                            )}

                            {tmsAccess?.status === 'success' && <Tms format="24" />}
                        </div>
                    )}
                </div>
            </div>
            <div className='footer'>
                <p>&copy; Megasportsworld 2020.</p>
            </div>
        </div>
    );
};

export default Dashboard;
