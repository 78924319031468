import React from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import { ChangePassword, Dashboard, ForgotPassword, ITPDHelpdesk, Login, Settings, Sites, VerifyAccount } from './modules';
import RequireAuth from './helpers/RequireAuth';

function App() {
    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                {/* public routes */}
                <Route index path='/' element={<Login />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/account/:token/change-password' element={<ChangePassword />} />
                <Route path='/account/:token/verify' element={<VerifyAccount />} />
                <Route path='*'>404 Not Found!</Route>

                {/* protected routes */}
                <Route element={<RequireAuth />}>
                    <Route path='cRJxA' element={<Dashboard />}>
                        <Route path='dashboard' element={<Sites />} />
                        <Route path='settings' element={<Settings />} />
                        <Route path='itpd-helpdesk' element={<ITPDHelpdesk />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
