import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../api/axios";
import Error from "../../components/error/Error";
import logo from '../../assets/msw-logo-white.png';
import './change-password.scss';

const ChangePassword: React.FC = () => {
    const navigate = useNavigate();
    const { token } = useParams<{ token: string }>();

    const errorPasswordRef = useRef<HTMLParagraphElement | null>(null);
    const errorSecurityAnswerRef = useRef<HTMLDivElement | null>(null);
    const errorChangePasswordRef = useRef<HTMLDivElement | null>(null);
    const successChangePasswordRef = useRef<HTMLDivElement | null>(null);

    const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [pageError, setPageError] = useState<boolean>(false);
    const [pageErrorMessage, setPageErrorMessage] = useState<string>('');

    const [tokenExpiration, setTokenExpiration] = useState<string>('');
    const [securityQuestion, setSecurityQuestion] = useState<string>('');
    const [userID, setUserID] = useState<string>('');

    const [securityAnswer, setSecurityAnswer] = useState<string>('');
    const [errorMessageSecurityAnswer, setErrorMessageSecurityAnswer] = useState<string>('');
    const [formSubmitSecurityAnswer, setFormSubmitSecurityAnswer] = useState<boolean>(false);

    const [correctAnswer, setCorrectAnswer] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [errorMessageChangePassword, setErrorMessageChangePassword] = useState<string>('');
    const [successMessageChangePassword, setSuccessMessageChangePassword] = useState<string>('');
    const [formSubmitChangePassword, setFormSubmitChangePassword] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    useEffect(() => {
        const fetchSecurityQuestion = async () => {
            try {
                const response = await axios.get('getpasswordresetdata', {
                    params: { t: token },
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN 
                    }
                });
                
                setUserID(response.data.data.userid);
                setSecurityQuestion(response.data.data.securityquestion);
                setTokenExpiration(response.data.data.pwdtokenexpiration);
            } catch (err: any) {
                setPageErrorMessage(err?.response ? err.response.data.msg : 'No server response!');
                setPageError(true);
            }
        };

        fetchSecurityQuestion();
    }, [token]);

    useEffect(() => {
        setErrorMessageSecurityAnswer('');
    }, [securityAnswer]);

    useEffect(() => {
        setErrorMessageChangePassword('');
    }, [password, confirmPassword]);

    const handleSubmitSecurityAnswer = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitSecurityAnswer(true);

        try {
            const response = await axios.post('checksecurityanswer', JSON.stringify({ userID, securityAnswer }), {
                headers: { 'Content-Type': 'application/json', 'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN }
            });
                
            if (response.data.status === 'success') {
                setCorrectAnswer(true);
            }
        } catch (err: any) {
            setErrorMessageSecurityAnswer(err?.response ? err.response.data.msg : 'No server response!');
            errorSecurityAnswerRef.current?.focus();
        } finally {
            setFormSubmitSecurityAnswer(false);
        }
    };

    const handleShowPassword = () => {
        setShowPassword(prev => !prev);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const isValid = value.length >= 6 && value.length <= 10 && /[A-Z]/.test(value) && /[a-z]/.test(value) && /[0-9]/.test(value);
        
        setPasswordError(!isValid);
        if (!isValid) errorPasswordRef.current?.focus();

        setPassword(value);
    };

    const handleSubmitChangePassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitChangePassword(true);
        
        if (password === confirmPassword) {
            try {
                const response = await axios.post('updatepassword', JSON.stringify({ userID, password }), {
                    headers: { 'Content-Type': 'application/json', 'X-Camp-Token': process.env.REACT_APP_CAMP_TOKEN }
                });

                setSuccessMessageChangePassword(response.data.msg);
                successChangePasswordRef.current?.focus();
                setTimeout(() => navigate('/'), 2000);
            } catch (err: any) {
                setErrorMessageChangePassword(err?.response ? err.response.data.msg : 'No server response!');
                errorChangePasswordRef.current?.focus();
            } finally {
                setFormSubmitChangePassword(false);
            }
        } else {
            setErrorMessageChangePassword('Passwords do not match!');
            errorChangePasswordRef.current?.focus();
            setFormSubmitChangePassword(false);
        }
    };
    
    return (
        <>
            {pageError  
                ? <Error message={pageErrorMessage} />
                : (tokenExpiration && currentDateTime > tokenExpiration) 
                    ? <Error message='Password reset token already expired!' />
                    : (
                        <div className={`change-password ${tokenExpiration && 'hide'}`}>
                            <div className='container'>
                                <div className='form-wrapper'>

                                    <div ref={errorSecurityAnswerRef} className={`message error ${errorMessageSecurityAnswer && 'show'}`} aria-live='assertive'>
                                        <p>
                                            <i className="fa fa-exclamation-triangle"></i> 
                                            <span>{errorMessageSecurityAnswer}</span>
                                        </p>
                                    </div>

                                    <div ref={errorChangePasswordRef} className={`message error ${errorMessageChangePassword && 'show'}`} aria-live='assertive'>
                                        <p>
                                            <i className="fa fa-exclamation-triangle"></i> 
                                            <span>{errorMessageChangePassword}</span>
                                        </p>
                                    </div>

                                    <div ref={successChangePasswordRef} className={`message success ${successMessageChangePassword && 'show'}`} aria-live='assertive'>
                                        <p>
                                            <i className="fa fa-check-circle"></i> 
                                            <span>{successMessageChangePassword}</span>
                                        </p>
                                    </div>

                                    <div className='header'>
                                        <div className='logo-container'>
                                            <div className='logo'>
                                                <img src={logo} alt='logo' />
                                            </div>
                                        </div>

                                        <div className='waves'>
                                            <svg className='waves' xmlns='http://www.w3.org/2000/svg' viewBox='0 24 150 28' preserveAspectRatio='none' shapeRendering='auto'>
                                                <defs>
                                                    <path id='gentle-wave' d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z' />
                                                </defs>
                                                <g className='parallax'>
                                                    <use xlinkHref='#gentle-wave' x='48' y='0' fill='rgba(255,255,255,0.7)' />
                                                    <use xlinkHref='#gentle-wave' x='48' y='3' fill='rgba(255,255,255,0.5)' />
                                                    <use xlinkHref='#gentle-wave' x='48' y='5' fill='rgba(255,255,255,0.3)' />
                                                    <use xlinkHref='#gentle-wave' x='48' y='7' fill='#fff' />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>

                                    { !correctAnswer ? (
                                        <form onSubmit={handleSubmitSecurityAnswer}>
                                            <div className='body'>
                                                <p className='security-question'>{securityQuestion}</p>
                                                <input 
                                                    type='password' 
                                                    onChange={(e) => setSecurityAnswer(e.target.value)} 
                                                    value={securityAnswer} 
                                                    placeholder='Security Answer' 
                                                    autoComplete='off' 
                                                    required 
                                                    disabled={formSubmitSecurityAnswer || !securityQuestion}
                                                />
                                                <button disabled={formSubmitSecurityAnswer || !securityQuestion}>
                                                    SUBMIT 
                                                    {formSubmitSecurityAnswer && <i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>}
                                                </button>
                                            </div>
                                        </form>
                                    ) : (
                                        <form id='frmChangePassword' onSubmit={handleSubmitChangePassword}>
                                            <div className='body'>
                                                <div>
                                                    <label className='form-group'>
                                                        <input 
                                                            type={showPassword ? 'text' : 'password'} 
                                                            onChange={handlePasswordChange}
                                                            value={password}
                                                            placeholder="New Password" 
                                                            autoComplete="off" 
                                                            required 
                                                            disabled={formSubmitChangePassword}
                                                        />
                                                        <button className="change-view" type="button" onClick={handleShowPassword}>
                                                            <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                                        </button>
                                                    </label>
                                                    <p ref={errorChangePasswordRef} className={`error-message ${passwordError && 'show'}`}>
                                                        Password should be between 6 to 10 characters and a combination of an uppercase, lowercase and a number.
                                                    </p>
                                                </div>

                                                <div>
                                                    <input 
                                                        type="password" 
                                                        onChange={(e) => setConfirmPassword(e.target.value)} 
                                                        value={confirmPassword}
                                                        placeholder="Confirm Password" 
                                                        autoComplete="off" 
                                                        required 
                                                        disabled={formSubmitChangePassword}
                                                    />
                                                </div>
                                                <button disabled={formSubmitChangePassword}>
                                                    SUBMIT 
                                                    {formSubmitChangePassword && <i className='fa fa-spinner fa-spin' style={{marginLeft: '5px'}}></i>}
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
            }
        </>
    );
}

export default ChangePassword;
