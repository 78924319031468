import React from 'react';
import './success.scss';
import logo from '../../assets/msw-logo.png';

interface SuccessProps {
    message: string;
}

const Success: React.FC<SuccessProps> = ({ message }) => {
    return (
        <div className='rty'>
            <div className='success'>
                <p className='message'>
                    <i className='fa fa-check-circle'></i>
                    <span>{message}</span>
                </p>
                <div className='logo'>
                    <img src={logo} alt='logo' />
                    <div className='title'>
                        <p>Centralized <span>Access</span></p>
                        <p>Management <span>Program</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Success;
